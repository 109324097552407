// TODO: Fix eslint errors.
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable write-good-comments/write-good-comments */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */

import React from 'react';
import LanguageSwitcher from '@UI/LanguageSwitcher';
import UtilityNavigation from '@UI/UtilityNavigation';

const languageFormatter = require('@Utils/languageFormatter');

class Header extends React.Component {
  getRoutes(routes) {
    return routes;
  }

  getLinks(links) {
    const menuLinks = {};

    // Fix menu links language code
    Object.keys(links || {}).forEach((lang) => {
      const languageCode = languageFormatter(lang);
      menuLinks[languageCode] = links[lang];
    });

    return menuLinks;
  }

  constructor(props) {
    super(props);

    const routes = this.getRoutes(props.routes);
    const links = this.getLinks(props.links);

    // Fix current language
    const currentLanguage = languageFormatter(props.currentLanguage);

    const currentRouteBasePath = this.getBaseUrl(props.currentRoute, props.currentRoute?.path) || '';
    const currentRouteUrl = props.currentRoute?.url || '';
    const homepageUrl = routes[currentLanguage]?.homepage?.url || '/';
    const currentLangUpper = currentLanguage.toUpperCase();
    const myRandstadBaseUrl = (homepageUrl + (process.env[`REACT_APP_MYRANDSTAD_${currentLangUpper}`] ?? process.env.REACT_APP_MYRANDSTAD_URL))
      .replace(/^\/\/?/, '/');
    const logo = this.getLogo();

    const mainMenuItems = links[currentLanguage]?.main || [];

    // Get (ordered) languages from the s3 file and filter only these with routes
    const languageItems = Object.keys(links)
      .filter((language) => Object.keys(routes[language] || []).length > 0)
      .map((language) => ({
        language,
        isActive: language === currentLanguage,
        url: language === currentLanguage
          ? null : this.getBaseUrl(props.currentRoute, routes[language]?.[props.currentRoute.routeName]?.url || ''),
      }));

    // Get the menu item url that best matches the current url
    // (from a two level navigation - without homepage '/' that will always match)
    const currentMenuItemUrl = mainMenuItems
      .map((item) => [item, item.children]).flat(2)
      .filter((item) => (currentRouteBasePath.startsWith(item.url) || currentRouteUrl.startsWith(item.url))
        && item.url !== '/')
      .sort((a, b) => b.url.length - a.url.length)[0]?.url || null;

    // Set active trail
    mainMenuItems.map((menuItem) => {
      menuItem.isActive = menuItem.url === currentMenuItemUrl;
      menuItem.children.map((child) => {
        child.isActive = child.url === currentMenuItemUrl || child.url === props.url;
        if (child.isActive) {
          menuItem.isActive = true;
        }
      });
    });

    const utilityMenuItems = links[currentLanguage]?.utility
      ? links[currentLanguage].utility
      : [];

    this.state = {
      languageItems,
      homepageUrl,
      logo,
      myRandstadBaseUrl,
      mainMenuItems,
      utilityMenuItems,
      logoClass: '',
      theme: '',
      currentLanguage,
    };
  }

  getBaseUrl(route, url) {
    switch (route && route.routeName ? route.routeName : null) {
      case 'workforce360':
      case 'press':
      case 'career':
        return url.replace(':searchParams*', '');

      default:
    }

    return url;
  }

  getLogo() {
    return `
      <svg viewBox="0 0 205 30.64" fill="#ffffff">
        <title>randstad</title>
        <path d="M15.95,30.32H20.5V18.87a2.26,2.26,0,0,0-.67-1.62l-6.75-6.75a2.25,2.25,0,0,0-1.62-.67H0v4.56H12.53a3.42,3.42,0,0,1,3.42,3.42Z" transform="translate(0 -0.18)"></path>
        <path d="M27.28,30.32H22.72V18.87a2.26,2.26,0,0,1,.67-1.62l6.75-6.75a2.25,2.25,0,0,1,1.62-.67H43.22v4.56H30.69a3.42,3.42,0,0,0-3.42,3.42Z" transform="translate(0 -0.18)"></path>
        <path d="M63.8,9.82h3.52V13h.08a6.24,6.24,0,0,1,5.34-3.66,10.69,10.69,0,0,1,2.49.28V13a5.6,5.6,0,0,0-2.09-.36c-3.38,0-5.59,3.22-5.59,8.37v9.33H63.8Z" transform="translate(0 -0.18)"></path>
        <path d="M179.24,22.35c0,3.09-2,5.44-5.7,5.44-1.72,0-3.81-1.18-3.81-3.36,0-3.65,5.06-4,7.1-4,.8,0,1.6.08,2.41.08Zm-11-7.87a9.52,9.52,0,0,1,5.94-2.13c3.77,0,5.05,1.84,5.05,5.38-1.48-.08-2.53-.08-4-.08-3.89,0-9.52,1.6-9.52,6.75,0,4.5,3.1,6.42,7.51,6.42a7.6,7.6,0,0,0,6.34-3.11h.08v2.61H183V17.79c0-5.6-2.36-8.47-8.21-8.47A13.1,13.1,0,0,0,168,11.24Z" transform="translate(0 -0.18)"></path>
        <path d="M88,22.35c0,3.09-2,5.44-5.7,5.44-1.73,0-3.81-1.18-3.81-3.36,0-3.65,5.06-4,7.1-4,.8,0,1.6.08,2.41.08ZM77,14.48A9.52,9.52,0,0,1,83,12.35c3.77,0,5.06,1.84,5.06,5.38-1.48-.08-2.53-.08-4-.08-3.89,0-9.52,1.6-9.52,6.75,0,4.5,3.1,6.42,7.51,6.42a7.6,7.6,0,0,0,6.34-3.11h.08v2.61H91.8V17.79c0-5.6-2.36-8.47-8.21-8.47a13.1,13.1,0,0,0-6.74,1.92Z" transform="translate(0 -0.18)"></path>
        <path d="M94.88,9.82h3.64V13h.08a7.39,7.39,0,0,1,6.73-3.72c5.31,0,7.66,3.28,7.66,8.79V30.32h-3.76V19.69c0-4.79-1-7.13-4.35-7.33-4.31,0-6.24,3.47-6.24,8.48v9.48H94.88Z" transform="translate(0 -0.18)"></path>
        <path d="M137.55,26.33a10.06,10.06,0,0,0,4.7,1.45c1.72,0,3.85-.73,3.85-2.95,0-3.76-8.87-3.43-8.87-9.21,0-4.27,3.18-6.31,7.23-6.31a15.49,15.49,0,0,1,4.7.81l-.32,3.27a11,11,0,0,0-4-1.05c-1.92,0-3.61.81-3.61,2.51,0,4.2,8.87,3,8.87,9.58,0,4.39-3.5,6.39-7.15,6.39a11.84,11.84,0,0,1-5.62-1.12Z" transform="translate(0 -0.18)"></path>
        <path d="M164.48,12.85h-5.43V24.11c0,2.38,1.45,3.67,3.17,3.67a4.65,4.65,0,0,0,2.57-.73v3.2a11.94,11.94,0,0,1-3.21.56c-3.89,0-6.29-1.83-6.29-5.94v-12h-4.62v-3h4.62V5.08l3.76-1.2V9.82h5.43Z" transform="translate(0 -0.18)"></path>
        <path d="M189.56,20c-.08-3.76,1.53-7.64,5.62-7.64s6.06,3.92,6.06,7.76c0,3.43-1.77,7.68-6,7.68C191.17,27.79,189.48,23.22,189.56,20ZM201.4,30.32H205V.18h-3.76V12.6h-.08C200,10.72,198,9.32,194.38,9.32c-5.94,0-8.84,4.85-8.84,10.37s2.61,11.13,8.8,11.13a8.22,8.22,0,0,0,7-3.36h.08Z" transform="translate(0 -0.18)"></path>
        <path d="M119.57,20c-.08-3.76,1.53-7.64,5.62-7.64s6.06,3.92,6.06,7.76c0,3.43-1.77,7.68-6,7.68C121.17,27.79,119.48,23.22,119.57,20Zm11.84,10.33H135V.18h-3.76V12.6h-.08C130,10.72,128,9.32,124.38,9.32c-5.94,0-8.84,4.85-8.84,10.37s2.61,11.13,8.8,11.13a8.22,8.22,0,0,0,7-3.36h.08Z" transform="translate(0 -0.18)"></path>
      </svg>
    `;
  }

  render() {
    const {
      myRandstadBaseUrl, currentLanguage,
    } = this.state;
    const ALLOW_MY_RANDSTAD = process.env.REACT_APP_MYRANDSTAD_ENABLED && process.env.REACT_APP_MYRANDSTAD_ENABLED === 'true';
    const MY_RANDSTAD_URL = myRandstadBaseUrl;
    const currentLangUpper = currentLanguage.toUpperCase();
    const LOGIN_URL = (process.env[`REACT_APP_MYRANDSTAD_LOGIN_${currentLangUpper}`] ?? (process.env.REACT_APP_MYRANDSTAD_LOGIN_URL ?? `${myRandstadBaseUrl}/login/`));
    const REGISTER_CV = process.env.REACT_APP_REGISTER_CV_ENABLED && process.env.REACT_APP_REGISTER_CV_ENABLED === 'true';

    let modalBackground = 'bg-variant-brand-tertiary';

    if (this.state.theme === 'sph') {
      modalBackground = 'bg-brand--light-grey';
    }

    const menuAttributes = (menuItemLength) => {
      // If item has children, return expanded attributes.
      if (menuItemLength > 0) {
        return {
          'data-rs-collapsible': '',
          'data-rs-toggable': '',
        };
      }

      return {};
    };

    /* eslint-disable */
    return (
      <header {...this.props.classes}>
        {/* This tag should always be nav. Language switcher use it. */}
        <nav className="navigation" role="navigation" aria-label={intl.formatMessage({ id: 'MainNavigationMenu.Heading' })} id="block-main-navigation">
          <div className="wrapper navigation__wrapper">
            <div className="navigation__top">
              <a
                aria-label="logo"
                className={`${this.state.logoClass} logo`}
                href={this.state.homepageUrl}
                dangerouslySetInnerHTML={{ __html: this.state.logo }}
              />
              <ul className="navigation__menu navigation__menu--main hidden--until-l">
                {
                this.state.mainMenuItems.map((menuItem, index) => (
                  <li
                    key={index}
                    className={`navigation__menu-item${menuItem.isActive ? ' navigation__menu-item--active' : ''}`}
                  >
                    <a href={menuItem.url} className={menuItem?.options?.attributes?.class || null}>{menuItem.title}</a>
                  </li>
                ))
              }
              </ul>
              <ul className="navigation__service">
                {REGISTER_CV && (
                <li className="navigation__service-item flex-shrink-0">
                  <a href="/job-seeker/submit-your-cv/" className="navigation__service-link hidden--from-l">
                    <span className="icon icon--inline">
                      <svg><use xlinkHref="/themes/custom/bluex/dist/assets/image/icons.svg#document" /></svg>
                    </span>
                  </a>
                  <a href="/job-seeker/submit-your-cv/" className="navigation__service-link hidden--until-l">
                    <span className="icon icon--inline">
                      <svg><use xlinkHref="/themes/custom/bluex/dist/assets/image/icons.svg#document" /></svg>
                    </span>
                    <span>register CV</span>
                  </a>
                </li>
                )}
                {ALLOW_MY_RANDSTAD && (
                <li className="navigation__service-item">
                  <a
                    href={LOGIN_URL}
                    className="navigation__service-link navigation__service-my-randstad hidden--from-l"
                  >
                    <span className="icon icon--inline">
                      <svg><use xlinkHref="/themes/custom/bluex/dist/assets/image/icons.svg#person" /></svg>
                    </span>
                  </a>
                  <a
                    href="#"
                    className="navigation__service-link navigation__service-my-randstad hidden--until-l"
                    data-rs-popover-trigger="login-popover"
                  >
                    <span className="icon icon--inline">
                      <svg><use xlinkHref="/themes/custom/bluex/dist/assets/image/icons.svg#person" /></svg>
                    </span>
                    <span id="navigation__service-user-text" className="hidden--until-l">my randstad</span>
                  </a>
                </li>
                )}
                <li className="navigation__service-item hidden--from-l">
                  <button className="button--icon-only button--hamburger" data-rs-navigation-menu-icon="" data-rs-navigation-menu-labels="" aria-label="open menu">
                    <span className="icon icon--hamburger" />
                  </button>
                </li>
              </ul>
              <div className="navigation__link-bar flex hidden--until-l">
                <UtilityNavigation items={this.state.utilityMenuItems} />
                <LanguageSwitcher items={this.state.languageItems} extraClasses="l:ml-s" />
              </div>

              <div id="navigationPopup" />
            </div>
            <ul className="navigation__menu navigation__menu--sub hidden--until-l">
              {
              this.state.mainMenuItems.map((menuItem) => {
                if (menuItem.isActive && menuItem.children && menuItem.children.length) {
                  return menuItem.children.map((childMenuItem, index) => (
                    <li
                      key={index}
                      className={`navigation__menu-item${childMenuItem.isActive ? ' navigation__menu-item--active' : ''}`}
                    >
                      <a href={childMenuItem.url}>{childMenuItem.title}</a>
                    </li>
                  ));
                }
              })
            }
            </ul>
          </div>
        </nav>
        <div className="modal modal--navigation hidden--from-l" data-rs-navigation="true">
          <div className={`modal__dialog ${modalBackground}`}>
            <div className="modal__header" data-rs-navigation-modal-header="true" />
            <div className="modal__main" data-rs-navigation-modal-main="true">
              <nav className="navigation-accordion">
                <ul className="link-list link-list--single accordion accordion--s">
                  {
                  this.state.mainMenuItems.map((menuItem, index) => (
                    <li key={index} className="link-list__item">
                      <div
                        className="collapsible__trigger"
                        {...menuAttributes(menuItem.children.length)}
                      >
                        <div className="link-list__link">
                          <a href={menuItem.url}>{menuItem.title}</a>
                          {
                            menuItem.children && menuItem.children.length
                              ? (
                                <span className="icon toggle-arrow" data-rs-collapsible-button="" role="button">
                                  <svg>
                                    <use xlinkHref="/themes/custom/bluex/dist/assets/image/icons.svg#chevron-down" />
                                  </svg>
                                </span>
                              )
                              : ''
                          }
                        </div>
                      </div>
                      <div
                        className="collapsible__content"
                        data-rs-collapsible-content=""
                        aria-hidden="true"
                      >
                        {
                          menuItem.children && menuItem.children.length
                            ? (
                              <ul className="navigation-accordion__sub">
                                {menuItem.children.map((childMenuItem, childIndex) => (
                                  <li key={childIndex}>
                                    <a href={childMenuItem.url}>{childMenuItem.title}</a>
                                  </li>
                                ))}
                              </ul>
                            )
                            : ''
                        }
                      </div>
                    </li>
                  ))
                }
                  {ALLOW_MY_RANDSTAD && <li className="link-list__item hidden--anonyoums">
                    <div className="collapsible__trigger" data-rs-collapsible="" aria-expanded="false" data-rs-toggable="">
                      <div className="link-list__link">
                      {/* Can be changed from auth-widget by the id. See DE auth-widget.js */}
                      <a id="mr-mobile-navigation-menu-title" href={`${MY_RANDSTAD_URL}/`}>my randstad</a>
                      <span className="icon toggle-arrow" data-rs-collapsible-button="" role="button">
                        <svg>
                          <use xlinkHref="/themes/custom/bluex/dist/assets/image/icons.svg#chevron-down" />
                        </svg>
                      </span>
                    </div>
                    </div>
                    {/* Placeholder for my randstad menu, that's populated by auth-widget. */}
                    <div className="collapsible__content" id="navigationMobileMR" data-rs-collapsible-content="" aria-hidden="true" />
                  </li>}
                </ul>
                <LanguageSwitcher items={this.state.languageItems} />
              </nav>
            </div>
          </div>
        </div>
        <div className="toast" data-rs-toast-anchor="logged-out" data-rs-toast="logged-out" data-rs-toast-logged-out="">
          <p className="toast__message">You are successfully logged out of your my randstad account</p>
          <button className="button--icon-only" data-rs-closable="data-rs-toast-logged-out" aria-label={intl.formatMessage({ id: 'Close' })}>
            <span className="icon fill--dark-blue-50">
              <svg>
                <use xlinkHref="/themes/custom/bluex/dist/assets/image/icons.svg#close" />
              </svg>
            </span>
            <span className="hidden--visually">{intl.formatMessage({ id: 'Close' })}</span>
          </button>
        </div>
        <div
          className="toast"
          data-rs-toast-anchor="delete-account"
          data-rs-toast="delete-account"
          data-rs-toast-delete-account=""
        >
          <p className="toast__message">You have successfully deleted your account</p>
          <button className="button--icon-only" data-rs-closable="data-rs-toast-delete-account" aria-label={intl.formatMessage({ id: 'Close' })}>
            <span className="icon fill--dark-blue-50">
              <svg>
                <use xlinkHref="/themes/custom/bluex/dist/assets/image/icons.svg#close" />
              </svg>
            </span>
            <span className="hidden--visually">{intl.formatMessage({ id: 'Close' })}</span>
          </button>
        </div>
      </header>
    );
    /* eslint-enable */
  }
}

export default Header;
